@import "./src/assets/scss/main.scss";










































































































.cabinet {
    margin-top: 85px;
    padding-bottom: 270px;
    display: flex;
    justify-content: space-between;

    @include media-breakpoint-down(md) {
        padding-bottom: 110px;
        margin-top: 25px;
        flex-direction: column;
    }

    &__nav {
        width: 250px;
    }

    &__content {
        width: calc(100% - 326px);
        background: #FFFFFF !important;
        box-shadow: -10px 6px 23px rgba(156, 156, 156, 0.1);
        border-radius: 12px;
        padding: 40px 50px 0px 49px;

        @include media-breakpoint-down(md) {
            padding-left: 15px;
            padding-right: 15px;
            width: 100%;
            padding-top: 0;
            background: #F5F5F5 !important;
            box-shadow: none;
            margin-top: -10px;
        }
    }

    &__header {
        margin-bottom: 29px;
        font-size: 24px;
    }
}

#app>div {

    background: #F5F5F5;
}

.footer {
    background: #fff;
}

.top-navbar {
    background: #fff !important;
}

.pay-int-account {

    background: #F5F5F5;
    border-radius: 9px;
    padding-top: 26px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 26px;
    align-items: center;

    //max-width: 645px;
    @include media-breakpoint-down(md) {
        display: flex;
        flex-direction: column;
        background: #FFFFFF;
        box-shadow: -10px 6px 23px rgba(156, 156, 156, 0.1);
        border-radius: 12px;
        padding-bottom: 13px;
    }

    &__desc {
        font-weight: 500;
        font-family: Montserrat;

        @include media-breakpoint-down(md) {
            text-align: center;
        }
    }

    &__btns {
        display: flex;
        align-items: center;
        padding-left: 70px;

        @include media-breakpoint-down(md) {
            flex-direction: column;
            justify-content: center;
            padding-left: 0;
        }

        input {
            height: 56px;
            width: 127px;
            background: #FFFFFF;
            border-radius: 73px;
            text-align: center;
            font-size: 16px;
            font-family: Montserrat;
            font-weight: bold;
            margin-right: 15px;
            border-color: #fff;

            @include media-breakpoint-down(md) {

                background: #F5F5F5;
                border-radius: 73px;
                margin-top: 10px;
            }
        }

        span {
            font-family: Montserrat;

            font-size: 16px;

            color: #000000;

            @include media-breakpoint-down(md) {
                padding-top: 10px;
                padding-bottom: 20px;
            }
        }

        button {
            margin-left: 30px;

            @include media-breakpoint-down(md) {
                margin-left: 0;
            }
        }
    }
}

.cabinet {

    &__content {}

    &__header {}
}

.innerBill {

    &__text {
        font-family: Montserrat;

        font-size: 16px;
        line-height: 23px;

        color: #636363;

        @include media-breakpoint-down(md) {
            text-align: center;
        }
    }

    &__bonusСard {}

    p {
        margin-bottom: 24px;
    }

    b {
        font-family: Montserrat;
        display: block;
        margin-top: 50px;
        font-weight: 600;
        font-size: 16px;
        line-height: 142.69%;
        /* identical to box height, or 23px */

        color: #000000;

        @include media-breakpoint-down(md) {
            display: none;
        }
    }
}

.bonusСard {
    border-radius: 12px;
    width: 395px;
    height: 209px;
    color: #fff;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    padding: 21px 25px 25px 33px;
    position: relative;
    background: url(../assets/bonusCardSec.png) 60% 40% no-repeat, url(../assets/bonusCard.png) 100% 45% no-repeat, #3F2A2C;

    @include media-breakpoint-down(md) {
        width: 335px;
        height: 171px;
        margin-left: 0;
    }

    &__name {
        font-weight: bold;
        font-size: 24px;
    }

    &__number {
        font-family: Montserrat;

        font-size: 16px;
    }

    &__sum {
        font-weight: bold;
        font-size: 20px;
        position: absolute;
        bottom: 20px;
    }
}

.pay-int-account {

    &__desc {}

    &__btns {}
}

.note {
    font-family: Montserrat;
    margin-top: 15px;
    padding-bottom: 75px;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    color: #636363;

    @include media-breakpoint-down(md) {
        text-align: center;
        padding-bottom: 20px;
    }
}

.footerContactMob {
    background: #F5F5F5 !important;
    width: 100%;
}

#app>div {
    @include media-breakpoint-down(md) {
        background: url(../assets/billMob.svg) 50% 72% no-repeat #F5F5F5;

    }
}
